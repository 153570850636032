import { Question } from '../../../models/question';
import { EventsByMonth } from '../../../models/eventsByMonth';
import {EventsService} from '../../../services/events.service';
import {QuestionService} from '../../../services/question.service';
import {Component, OnInit, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddQuestionModalComponent} from '../../modals/add-question-modal/add-question-modal.component';
import {EditQuestionModalComponent} from '../../modals/edit-question-modal/edit-question-modal.component';
import {SortableEvent} from 'sortablejs';
import * as Sortable from 'sortablejs';


@Component({
  selector: 'app-question-overview',
  templateUrl: './question-overview.component.html',
  styleUrls: ['./question-overview.component.css']
})
export class QuestionOverviewComponent implements OnInit {

  @Input() questions: Question[] = [];
  @Input() events: EventsByMonth[] = [];

  public options: Sortable.Options = {animation: 150, onUpdate: event => this.onUpdate(event)};
  public dirtyQuestions = false;

  private questionsCopy: Question[];

  constructor(private questionService: QuestionService,
              private eventsService: EventsService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.questionService.getQuestions().then(res => {
      if (res === null) {
        return;
      }
      this.questions = res.sort((question1, question2) => (question1.index < question2.index) ? -1 : 1);
      this.questionsCopy = this.questions;
    });

    this.eventsService.getEventsByMonth().then(res => {
      this.events = res;
    });
  }

  public openAddModal(): void {
    this.modalService.open(AddQuestionModalComponent, {size: 'lg'}).result.then((result: Question) => {
      this.questionService.addQuestion(result).then(question => {
        result.answers.forEach(answer => answer.questionId = question.id);
        if (this.questions.length === 0) {
          this.questions.push(question);
        } else {
          for (let i = 0; i < this.questions.length; i++) {
            if (question.index <= this.questions[i].index) {
              this.questions.splice(i, 0, question);
              break;
            } else if (!this.questions[i + 1]) {
              this.questions.push(question);
              break;
            }
          }
        }
      });
    });
  }

  public openEditModal(question: Question): void {
    const modalRef = this.modalService.open(EditQuestionModalComponent, {size: 'lg'});
    modalRef.componentInstance.question = question;
    modalRef.result.then((result: Question) => {
      this.questionService.updateQuestion(result);
      this.questions[this.questions.findIndex(qstn => qstn.id === result.id)] = result;
    });
  }

  public delete(id: number): void {
    this.questionService.removeQuestion(id);
    this.questions.splice(this.questions.findIndex(question => question.id === id), 1);
  }

  private onUpdate(event: SortableEvent): void {
    // TODO implement
  }

  public updateIndexes(): void {
    // TODO implement
  }

  private isQuestionDirty(question: Question): boolean {
    return question.index !== this.questionsCopy.find(q => q.id === question.id).index;
  }
}
