import {HomeComponent} from './components/pages/home/home.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {QuestionOverviewComponent} from './components/pages/question-overview/question-overview.component';
import {CulturalFitCheckComponent} from './components/pages/cultural-fit-check/cultural-fit-check.component';
import {LoginComponent} from './components/pages/login/login.component';
import {AuthorizeGuard} from './Guards/authorize.guard';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'question-overview', component: QuestionOverviewComponent, canActivate: [AuthorizeGuard]},
  {path: 'cultural-fit-check', component: CulturalFitCheckComponent},
  {path: 'login', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
