export class Boundary {

  private constructor(lowerBound: number, upperBound: number) {
    this.lowerBound = lowerBound;
    this.upperBound = upperBound;
  }

  private static readonly boundaries = [
    new Boundary(100, 101),
    new Boundary(92, 100),
    new Boundary(81, 92),
    new Boundary(81, 92),
    new Boundary(67, 81),
    new Boundary(50, 67),
    new Boundary(30, 50),
    new Boundary(0, 30),
  ];

  lowerBound: number;
  upperBound: number;


  public static getBoundary(num: number): Boundary {
    return this.boundaries.find(b => num >= b.lowerBound && num < b.upperBound);
  }
}
