<app-header></app-header>
<div class="container-fluid">
  <div class="container">
    <div class="row">
      <div class="col-xl-8 col-sm-12 col-primary-bottom">
        <h2 class="text-uppercase">
          MACHE JETZT UNSEREN CULTURAL FIT CHECK UND FINDE HERAUS…
        </h2>
        <h1>Passt du zu AraCom?</h1>
        <div class="text-block">
          <p>
            Mit Hilfe des Cultural Fit Checks findest du heraus, wie gut deine
            Werte und Arbeitsweise mit denen von AraCom übereinstimmen. Du
            möchtest wissen, wie gut wir zusammenpassen? Du möchtest wissen, was
            dich bei AraCom als Entwickler erwartet? Dann starte jetzt deinen
            <b>Cultural Fit Check</b>.
          </p>
          <p>
            Der Cultural Fit Check umfasst 15 Statements und du entscheidest,
            wie du zu den Aussagen stehst. Der Test beansprucht ca. 3 Minuten.
          </p>
          <p>
            Bei unserem Cultural Fit Check gibt es keine unerwünschten
            Antworten. Wir wünschen uns authentische Mitarbeiter, deshalb
            beantworte die Fragen intuitiv.
          </p>
          <p>
            Es ist keine Anmeldung erforderlich und die Antworten werden auch
            nicht gespeichert. Deine Anonymität wird somit gewahrt.
          </p>
        </div>
      </div>
      <div class="col align-self-center col-secondary-top">
        <a
          routerLink="/cultural-fit-check"
          id="check"
          class="btn btn-lg btn-primary bg-blue-wall w-100"
          role="button"
          ><b>jetzt</b><br />
          cultural fit check<br /><b>starten</b></a
        >
      </div>
    </div>
  </div>
  <div class="row pic-row justify-content-around">
    <div class="col-xl-4 col-lg-4 col-md-7 col-sm-7 col-12 pic-col" id="jobs">
      <a href="https://aracom.de/karriere/">
        <img
          class="img-fluid"
          src="assets/picture-row/standard/jobs.jpg"
          alt=""
        />
        <p class="txt-over-image">jobs bei aracom</p>
      </a>
    </div>
    <div
      class="col-xl-4 col-lg-4 col-md-7 col-sm-7 col-12 pic-col"
      id="reasons"
    >
      <a href="https://aracom.de/gruende/">
        <img
          class="img-fluid"
          src="assets/picture-row/standard/reasons.jpg"
          alt=""
        />
        <p class="txt-over-image">gründe für aracom</p>
      </a>
    </div>
    <div
      class="col-xl-4 col-lg-4 col-md-7 col-sm-7 col-12 pic-col"
      id="application"
    >
      <a href="https://aracom.de/bewerbungsprozess/">
        <img
          class="img-fluid"
          src="assets/picture-row/standard/application.jpg"
          alt=""
        />
        <p class="txt-over-image">bewerbung bei aracom</p>
      </a>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xl-8">
        <h2>WAS SAGT DER CULTURAL FIT CHECK AUS UND WIE FUNKTIONIERT ER?</h2>
        <h1>Die Antworten zu deinen Fragen:</h1>
        <div class="paragraph">
          <h3>1. WAS IST EIN CULTURAL FIT CHECK?</h3>
          <p class="text-block">
            Unser Cultural Fit Check ist ein Verfahren, um herauszufinden wie
            gut deine Wertevorstellung zu den Werten von AraCom passen.
          </p>
        </div>
        <div class="paragraph">
          <h3>2. WELCHES ZIEL VERFOLGT ARACOM MIT DEM CULTURAL FIT CHECK?</h3>
          <p class="text-block">
            Uns ist es wichtig, dass sich Bewerber vor ihrer Bewerbung mit den
            Werten unseres Unternehmens auseinandersetzen. Der Cultural Fit
            Check bietet einen guten Indikator, welcher zeigt, ob ein
            potenzieller Mitarbeiter auch langfristig Freude und Spaß in unserem
            AraCom-Team haben wird. Die Zufriedenheit unseres Teams hat oberste
            Priorität!
          </p>
        </div>
        <div class="paragraph">
          <h3>3. WIE IST EIN BEISPIELSSTATEMENT AUFGEBAUT?</h3>
          <p class="text-block">
            Arbeitsweise
            <br />
            -------------------
            <br />
            Selbst ein Einhorn im Raum kann mich nicht aus der Ruhe bringen. Ich
            arbeite stets fokussiert.
            <br />
            <br />
            Ich stimme voll zu | Ich stimme teilweise zu | Ich stimme nicht zu
            <br />
            <br />
            PS: In dem Cultural Fit Check findest du natürlich keine Fabelwesen.
          </p>
        </div>
        <div class="paragraph">
          <h3>4. GIBT ES EINE FESTE GEWICHTUNG FÜR DIE STATEMENTS?</h3>
          <p class="text-block">
            Unsere Statements sind unterschiedlich gewichtet. Ein paar wenige
            Statements bieten auch nur für dich einen Einblick in unsere Denk-
            und Arbeitsweise und fließen geringfügig in die Bewertung mit ein.
            Es können maximal 100 % erreicht werden.
          </p>
        </div>
      </div>
      <div class="col-xl-4 contact col-secondary-top">
        <h2>DU HAST NOCH FRAGEN?</h2>
        <h1 class="head-name">Deine Ansprechpartnerin:</h1>
        <hr class="mt-4" />
        <h4 class="my-4">Carolin Uhl</h4>
        <h2 class="text-pos">Teamleiterin Recruitment</h2>
        <div class="row contact-row">
          <div class="col-xl-8 col-lg-3 col-md-3 col-sm-4 col-7">
            <img
              src="assets/contact/CarolinUhl.jpg"
              alt="Carolin Uhl"
              class="img-fluid my-2 rounded-circle"
            />
          </div>
          <div class="col my-4">
            <p class="text-block">
              AraCom IT Services AG<br />
              Zentrale Augsburg<br />
              Daimlerstraße 13<br />
              D-86368 Gersthofen<br />
              Fon: +49 821 / 570453-22<br />
              Fax: +49 821 / 570453 -32<br />
              Mail: <a href="mailto:jobs@aracom.de">jobs@aracom.de</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
