import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventsByMonth } from '../models/eventsByMonth';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private apiUrl = 'api/events';

  constructor(private http: HttpClient) {
  }

  public getEventsByMonth(): Promise<EventsByMonth[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<EventsByMonth[]>(this.apiUrl, { headers }).toPromise();
  }

  public addFinishEvent(): Promise<any> {
    try {
      return this.http.post(this.apiUrl, {}).toPromise();
    } catch (error) {
      console.log(error);
    }
  }
}
