import {Question} from '../../../models/question';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {QuestionService} from '../../../services/question.service';
import {ChartOptions, ChartType} from 'chart.js';
import {Label, SingleDataSet} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {Answer, texts} from '../../../models/answer';
import {pluck} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {boundsResultMap} from '../../../models/result';
import {Boundary} from '../../../models/boundary';
import { EventsService } from '../../../services/events.service';

@Component({
  selector: 'app-question',
  templateUrl: './cultural-fit-check.component.html',
  styleUrls: ['./cultural-fit-check.component.css']
})
export class CulturalFitCheckComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('answerChildren') answerChildren: QueryList<ElementRef<HTMLLabelElement>>;
  @ViewChild('pdfTemplate') pdfTemplate: ElementRef;

  private maxScore = 0;
  private actualScore = 0;
  private answerChildrenSubscription: Subscription;


  // Chart Config
  public pieChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {enabled: false},
    plugins: {
      datalabels: {
        color: '#fff',

        font: (ctx) => {
          const width = ctx.chart.width;
          const size = Math.round(width / 10);
          return {
            size,
          };
        },
        formatter: (value, ctx) => {
          return ctx.chart.data.labels[ctx.dataIndex];
        },
      },
    }


  };
  public pieChartData: SingleDataSet = [0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [ '#367DD9', '#82B8D9' ],
      hoverBorderColor: ['transparent', 'transparent'],
      labelColors: ['#da2c4d', '#da2c4d']
    },
  ];
  public pieChartLabels: Label;

  public texts = texts;
  public index = 0;
  public showResult = false;
  public questions: Question[];
  public answers: Array<Answer>;

  public resultHeader: string;
  public resultText: string;

  constructor(
    private questionService: QuestionService,
    private eventsService: EventsService) {
  }

  ngAfterViewInit(): void {
    this.answerChildrenSubscription = this.answerChildren.changes.pipe(pluck('_results'))
      .subscribe((next: Array<ElementRef<HTMLLabelElement>>) => {
        const selectedAnswer = this.answers[this.index];
        if (!selectedAnswer || next.length === 0) {
          return;
        }
        next.find(elem => elem.nativeElement.id === selectedAnswer.id.toString()).nativeElement.classList.add('active');
      });
  }

  async ngOnInit(): Promise<Question[]> {
    await this.questionService.getQuestions().then(res => {
      this.questions = res.sort((question1, question2) => (question1.index < question2.index) ? -1 : 1);
    });
    this.answers = new Array<Answer>(this.questions.length);

    this.questions.forEach(q => {
      this.maxScore += Math.max.apply(Math, q.answers.map(a => a.score * q.weightingFactor));
    });
    return this.questions;
  }

  public set _index(index: number) {
    this.index = index;
  }

  public setPieChartData(): void {
    this.answers.forEach(a => {
      this.actualScore += a.score * this.questions.find(q => q.id === a.questionId).weightingFactor;
    });
    this.pieChartData[0] = this.actualScore;
    this.pieChartData[1] = this.maxScore - this.actualScore;

    this.pieChartLabels = [this.scoreInPercent + '%', ''];
  }

  public get scoreInPercent(): number {
    return Math.floor(((this.actualScore * 100) / this.maxScore));
  }

  public setAnswer(event: MouseEvent, answer: Answer): void {
    event.preventDefault();
    this.answers.splice(this.index, 1, answer);
  }

  ngOnDestroy(): void {
    this.answerChildrenSubscription.unsubscribe();
  }

  setResultData() {
    this.setPieChartData();
    const result = boundsResultMap.get(Boundary.getBoundary(this.scoreInPercent));
    this.resultHeader = result.header;
    this.resultText = result.text;
    this.showResult = true;
    this.eventsService.addFinishEvent();
  }

  generatePDF() {
    // this.pdfService.generatePdf(document.getElementById('pdfTemplate').innerHTML).then(res => {
      // const binaryData = [];
      // binaryData .push(res);
      // const fileURL = URL.createObjectURL(new Blob(binaryData));

      // const binaryString = window.atob(res);
      // const bytes = new Uint8Array(binaryString.length);
      // for (let i = 0; i < binaryString.length; i++) {
      //   bytes[i] = binaryString.charCodeAt(i);
      // }
      // const file = new Blob([bytes.buffer], {type: 'application/octet-stream'});
      // const fileURL = URL.createObjectURL(file);

    //   const file = new Blob([res], {type: 'application/pdf'});
    //   const fileURL = URL.createObjectURL(file);
    //   window.open(fileURL, '_blank');
    // });
  }
}
