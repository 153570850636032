import { Boundary } from './boundary';

export class Result {
  header: string;
  text: string;
}

const boundsResultMap = new Map<Boundary, Result>([
  [Boundary.getBoundary(100), {
    header: '100% Der ultimative Match',
    text: 'Wir passen perfekt zusammen! Wenn dein bisheriger Werdegang und dein Know-How zu der gewünschten Position passen,' +
      ' stünde deinem AraComler-Dasein nichts im Wege. Wir freuen uns schon sehr darauf, dich kennenzulernen!\n' +
      '\n' +
      'Bewirb dich noch heute – gerne kannst du deiner Bewerbung das Ergebnis des Cultural Fit Checks beilegen. \n'
  }],
  [Boundary.getBoundary(92), {
    header: '92 - 99% Top-Match',
    text: 'Unsere Wertevorstellungen passen super zusammen! Wenn dein bisheriger Werdegang und dein Know-How zu der gewünschten' +
      ' Position passen, stünde deinem AraComler-Dasein nichts im Wege. Du würdest grandios zu uns passen. \n' +
      '\n' +
      'Bewirb dich noch heute, denn wir freuen uns schon darauf, dich kennenzulernen! \n'
  }],
  [Boundary.getBoundary(81), {
    header: '81 – 91 % Sehr viele Gemeinsamkeiten',
    text: 'Wir sind auf einer Wellenlänge! Wenn dein bisheriger Werdegang und dein Know-How zu der gewünschten Position passen,' +
      ' stünde deinem AraComler-Dasein nichts im Wege.' +
      ' Du würdest wirklich gut zu uns passen, daher würden wir uns sehr über deine Bewerbung freuen!'
  }],
  [Boundary.getBoundary(67), {
    header: '67 – 80 % Viele Gemeinsamkeiten',
    text: 'Deine Wertevorstellungen decken sich größtenteils mit unseren.' +
      ' Auch wenn wir nicht exakt die gleichen Wertevorstellungen haben,' +
      ' denken wir, dass du unser Team gut ergänzen könntest. Wir würden uns sehr über deine Bewerbung freuen! '
  }],
  [Boundary.getBoundary(50), {
    header: '50 – 66 % Ähnliche Wertvorstellungen',
    text: 'Wir sind uns nicht bei allem einig, aber das ist auch nicht weiter schlimm, da es bei unseren Wertevorstellungen' +
      ' einige Gemeinsamkeiten gibt. Ob wir zusammenpassen, könnten wir vielleicht bei einem Bewerbungsgespräch herausfinden.' +
      ' Wir würden uns über deine Bewerbung freuen!'
  }],
  [Boundary.getBoundary(30), {
    header: '30 – 49 % Kleiner Fit', text: 'Es gibt einige Werte,' +
      ' die sowohl du als auch AraCom schätzen. Ob es die Ausschlaggebenden sind, könnten wir bei einem Bewerbungsgespräch herausfinden.' +
      ' Es ist nicht ausgeschlossen, dass wir nicht doch sehr gut zusammenpassen.' +
      ' Lass es uns herausfinden – wir würden uns über eine Bewerbung von dir freuen!'
  }],
  [Boundary.getBoundary(0), {
    header: '0 - 29 % Sehr geringer Fit ', text: 'Unsere Wertevorstellungen sind nahezu konträr.' +
      ' Bist du dennoch davon überzeugt, dass wir ein gutes Team wären? Wenn ja, dann bewirb dich bei uns. '
  }],
]);
export { boundsResultMap };

