<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-xl-8">
        <div class="d-flex flex-column align-items-center col-primary-top">
          <div class="d-flex">
            <h1 class="text-white">GET CONNECTED</h1>
          </div>
          <hr>
          <div class="row justify-content-between align-self-stretch">
            <div class="col logo-col">
              <a href="https://www.facebook.com/aracom.it">
                <img src="assets/footer/icons/001-facebook-logo.svg" alt="facebook" class="logo img-fluid">
              </a>
            </div>
            <div class="col logo-col">
              <a href="https://www.instagram.com/aracom_official/">
                <img src="assets/footer/icons/005-instagram.svg" alt="instagram" class="logo img-fluid">
              </a>
            </div>
            <div class="col logo-col">
              <a href="https://www.youtube.com/channel/UCok_hB2d4mH-GvPnJbqxMqg">
                <img src="assets/footer/icons/003-youtube-logo.svg" alt="youtube" class="logo img-fluid">
              </a>
            </div>
            <div class="col logo-col">
              <a href="https://www.xing.com/company/aracom">
                <img src="assets/footer/icons/004-xing.svg" alt="xing" class="logo img-fluid" id= "xing">
              </a>
            </div>
            <div class="col logo-col">
              <a href="https://www.linkedin.com/company/11797508/">
                <img src="assets/footer/icons/002-linkedin-logo.svg" alt="linkedin" class="logo img-fluid">
              </a>
            </div>
            <div class="col logo-col align-self-center">
              <a href="https://www.kununu.com/de/aracom-it-services">
                <img src="assets/footer/icons/logo_kununu_clean_neg.png" alt="kununu" class="logo img-fluid"
                     id="kununu">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-secondary-bottom footer-links-wrapper">
        <div class="d-flex footer-links">
          <a href="https://aracom.de/kontakt/" class="link-item text-block">KONTAKT</a>
          <a href="https://aracom.de/impressum-datenschutz/" class="link-item text-block">IMPRESSUM</a>
          <a href="https://aracom.de/impressum-datenschutz/" class="link-item text-block">DATENSCHUTZ</a>
          <a href="https://aracom.de/" class="link-item text-block"><b>MEHR ZU ARACOM</b></a>
        </div>
      </div>
    </div>
  </div>
</div>
