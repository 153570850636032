<div class="header">
  <div class="d-flex flex-row">
    <img
      src="assets/header/AraCom Logo-weiß.png"
      alt="AraCom Logo"
      class="img-fluid logo ml-lg-4 mt-lg-4 mt-2 ml-2"
    />
    <div class="ml-auto">
      <a
        class="btn btn-primary btn-home mr-lg-4 mt-lg-4 mt-2 mr-2 rounded font-weight-bold border-0"
        href="https://aracom.de/"
        role="button"
      >
        <i class="fas fa-home"></i>
      </a>
    </div>
  </div>
  <div class="container mt-5">
    <h1 class="text-white">Cultural Fit Check</h1>
  </div>
</div>
