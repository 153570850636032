import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {texts} from '../../../models/answer';
import {Question} from '../../../models/question';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-question-modal',
  templateUrl: './edit-question-modal.component.html',
  styleUrls: ['./edit-question-modal.component.css']
})
export class EditQuestionModalComponent implements OnInit {

  @Input() question: Question;

  @ViewChild('modalRef') modalRef: ElementRef;

  editQuestionForm = this.fb.group({
    id: [],
    title: ['', Validators.required],
    text: ['', Validators.required],
    weightingFactor: ['', Validators.required],
    index: ['', Validators.required],
    answers: this.fb.array(Array.from({length: texts.length}, () => this.fb.group({
      id: [],
      questionId: [],
      score: ['', Validators.required]
    })))
  });
  get answers(): FormArray {
    return this.editQuestionForm.get('answers') as FormArray;
  }
  public texts = texts;

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.editQuestionForm.setValue({
      id: this.question.id,
      index: this.question.index,
      title: this.question.title,
      text: this.question.text,
      weightingFactor: this.question.weightingFactor,
      answers: this.question.answers
    });
  }

  validate(): void {
    this.modalRef.nativeElement.classList.add('was-validated');
    if (this.editQuestionForm.valid) {
      this.modal.close(this.editQuestionForm.value);
    }
  }
}
