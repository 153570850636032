<app-header></app-header>
<ng-container *ngIf="questions && !showResult">
  <div class="container">
    <div class="col-lg-8 col-sm-12">
      <h2>{{ index + 1 }}. Frage</h2>
      <hr />
      <h1>{{ questions[index].title }}</h1>
      <p class="text-block q-text">{{ questions[index].text }}</p>
    </div>
    <div class="row align-items-end">
      <div class="col-12" data-toggle="buttons">
        <div class="row justify-content-center align-items-stretch">
          <div
            class="col-auto btn-group-toggle btn-answer"
            *ngFor="let answer of questions[index].answers; index as i"
          >
            <label
              #answerChildren
              class="btn btn-secondary test"
              (click)="setAnswer($event, answer)"
              [id]="answer.id"
            >
              <input type="radio" name="options" autocomplete="off" /><span
                [innerHTML]="texts[i]"
              ></span>
            </label>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-5 col-lg-3 col-md-6">
          <div class="d-flex flex-column-reverse">
            <div
              *ngIf="
                index + 1 != questions.length;
                then ctrlBtns;
                else resultBtn
              "
            ></div>
            <ng-template #ctrlBtns>
              <button
                id="next"
                class="btn btn-primary bg-blue-wall"
                [disabled]="!answers[index]"
                (click)="_index = index + 1"
              >
                Weiter
              </button>
              <button
                id="prev"
                class="btn btn-primary bg-blue-wall"
                [style.visibility]="index != 0 ? 'visible' : 'hidden'"
                (click)="_index = index - 1"
              >
                Zurück
              </button>
            </ng-template>
            <ng-template #resultBtn>
              <button
                id="result"
                class="btn btn-primary bg-blue-wall"
                (click)="setResultData()"
                [disabled]="!answers[index]"
              >
                Zum Ergebnis
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="progress-wrapper">
      <p class="text-uppercase text-block progress-text">
        Dein fortschritt: Frage {{ index + 1 }} von
        {{ questions.length }}
      </p>
      <div class="progress">
        <div
          class="progress-bar"
          [style.width.%]="((index + 1) / questions.length) * 100"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showResult">
  <div class="container">
    <div class="row">
      <h2 class="text-uppercase col-12 accomplished">geschafft!</h2>
      <div class="col-xl-8 col-12 result-text">
        <h1>Passt du zu uns? Deine Auswertung...</h1>
        <p class="p-result text-block">
          <b>{{ resultHeader }}</b>
          <br />
          {{ resultText }}
        </p>
        <p>
          <a
            href="https://aracom.de/festanstellung/"
            class="btn btn-primary bg-blue-wall px-5 py-3 blue"
            >JETZT BEWERBEN</a
          >
        </p>
      </div>
      <div class="col-xl-4 text-center result-chart">
        <h1 class="chart-header mb-3">Dein AraCom Fit</h1>
        <canvas
          class="chart"
          baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [plugins]="pieChartPlugins"
          [legend]="pieChartLegend"
          [colors]="pieChartColors"
        >
        </canvas>
      </div>
    </div>
  </div>
</ng-container>
<app-footer></app-footer>
