<div class="modal-header">
  <h5 class="modal-title">Frage hinzufügen</h5>
</div>
<div #modalRef class="modal-body">
  <form [formGroup]="addQuestionForm" novalidate>
    <div class="form-group">
      <label>Titel</label>
      <input type="text" formControlName="title" class="form-control" required>
    </div>
    <div class="form-group">
      <label>Text</label>
      <textarea class="form-control" formControlName="text" rows="3" required></textarea>
    </div>
    <div class="row">
      <div class="form-group col">
        <label>Gewichtung</label>
        <input type="number" formControlName="weightingFactor" class="form-control" required>
      </div>
      <div class="form-group col">
        <label>Index</label>
        <input type="number" formControlName="index" class="form-control" required>
      </div>
    </div>
    <div formArrayName="answers" class="row">
      <div [formGroupName]="i" class="form-group col" *ngFor="let control of answers.controls; index as i">
        <label [innerHTML]="texts[i]"></label>
        <input type="number" formControlName="score" class="form-control" required>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="modal.dismiss()">Abbrechen</button>
  <button type="submit" class="btn btn-primary" (click)="validate()">Hinzufügen</button>
</div>
