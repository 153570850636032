import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppComponent} from './app.component';
import {HomeComponent} from './components/pages/home/home.component';
import {QuestionOverviewComponent} from './components/pages/question-overview/question-overview.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {CulturalFitCheckComponent} from './components/pages/cultural-fit-check/cultural-fit-check.component';
import {AddQuestionModalComponent} from './components/modals/add-question-modal/add-question-modal.component';
import {EditQuestionModalComponent} from './components/modals/edit-question-modal/edit-question-modal.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ChartsModule} from 'ng2-charts';
import {SortablejsModule} from 'ngx-sortablejs';
import {LoginComponent} from './components/pages/login/login.component';
import {CommonModule} from '@angular/common';
import {JwtInterceptor} from './interceptors/jwt.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    QuestionOverviewComponent,
    CulturalFitCheckComponent,
    AddQuestionModalComponent,
    EditQuestionModalComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    ChartsModule,
    SortablejsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
