import { Answer } from './answer';
export class Question {
    id: number;
    weightingFactor: number;
    text: string;
    title: string;
    index: number;
    dirty: boolean;
    answers: Answer[];
}
