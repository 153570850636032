import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {texts} from '../../../models/answer';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.css']
})
export class AddQuestionModalComponent implements OnInit {

  @ViewChild('modalRef') modalRef: ElementRef;

  addQuestionForm = this.fb.group({
    title: ['', Validators.required],
    text: ['', Validators.required],
    weightingFactor: ['', Validators.required],
    index: ['', Validators.required],
    answers: this.fb.array(Array.from({length: texts.length}, () => this.fb.group({
      score: ['', Validators.required]
    })))
  });

  get answers(): FormArray {
    return this.addQuestionForm.get('answers') as FormArray;
  }

  public texts = texts;

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
  }

  validate(): void {
    this.modalRef.nativeElement.classList.add('was-validated');
    if (this.addQuestionForm.valid) {
      // const question = new Question();
      // question.index = this.addQuestionForm.value.index;
      // question.text = this.addQuestionForm.value.text;
      // question.title = this.addQuestionForm.value.title;
      // question.weightingFactor = this.addQuestionForm.value.weightingFactor;
      // question.answers = Array.from({length: this.addQuestionForm.value.answers.length}, (v, i) => {
      //   const answer = new Answer();
      //   answer.score = this.addQuestionForm.value.answers[i];
      //   return answer;
      // });
      this.modal.close(this.addQuestionForm.value);
    }
  }
}
