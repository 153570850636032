import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Question} from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private apiUrl = 'api/question';

  constructor(private http: HttpClient) {
  }

  public getQuestions(): Promise<Question[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<Question[]>(this.apiUrl, {headers}).toPromise();
  }

  public addQuestion(question: Question): Promise<Question> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    try {
      return this.http.post<Question>(this.apiUrl, JSON.stringify(question), {headers}).toPromise();
    } catch (error) {
      console.log(error);
    }
  }

  public updateQuestion(question: Question): Promise<Question> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put<Question>(this.apiUrl, JSON.stringify(question), {headers}).toPromise();
  }

  public updateQuestions(questions: Question[]): Promise<Question[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put<Question[]>(this.apiUrl + '/multiple', JSON.stringify(questions), {headers}).toPromise();
  }

  public removeQuestion(id: number): Promise<Question> {
    return this.http.delete<Question>(this.apiUrl + '/' + id.toString()).toPromise();
  }
}
