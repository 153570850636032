<nav class="navbar sticky-top navbar-dark bg-dark">
  <h1 class="mr-auto text-white">Fragen</h1>
  <button class="btn btn-outline-info my-2 my-sm-0" type="button" [disabled]="!dirtyQuestions" (click)="updateIndexes()">speichern</button>
</nav>

<div class="container-fluid">
  <h2>Statistiken</h2>
  <table class="table table-bordered table-sm" style="width: auto;">
    <tr>
      <th>Monat</th>
      <th>Angefangen</th>
      <th>Abgeschlossen</th>
    </tr>
    <tr *ngFor="let event of events;let i=index">
      <th>{{event.month}}/{{event.year}}</th>
      <td class="text-right">{{event.startWizard}}</td>
      <td class="text-right">{{event.finishWizard}}</td>
    </tr>
  </table>
  <br/>
  <div class="drag-area">
    <div id="grid" class="row" [sortablejs]="questions" [sortablejsOptions]="options">
      <div [id]="question.id" *ngFor="let question of questions;let i=index"
           class="grid-square col-sm-6 col-md-5 col-lg-4 col-xl-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{question.title}}</h5>
            <p class="card-text">{{question.text}}</p>
            <div class="row">
              <div class="col-auto mr-auto">
                <button class="btn btn-primary" type="button" (click)="openEditModal(question)">
                  Bearbeiten
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-danger" type="button" (click)="delete(question.id)">
                  Löschen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-success btn-circle-fab d-flex justify-content-center align-items-center"
          (click)="openAddModal()">
    <svg width="100%" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
      <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
    </svg>
  </button>
</div>
